import { RouterIcon } from 'src/app/root/model/root.model';
import { CustomerRole } from '../constants';
import { environment } from 'src/environments/environment';

export interface RouteMetadata {
    route: string;
    title: string;
    icon: string;
    api?: string;
    hasRole?: CustomerRole;
    description?: string;
}

export const defaultRouteMetadata: RouteMetadata = {
    route: '',
    title: 'common.unknown',
    icon: RouterIcon.DEFAULT,
};

export const routesMetadata: RouteMetadata[] = [
    {
        route: '/about',
        title: 'about',
        icon: RouterIcon.ABOUT,
        hasRole: CustomerRole.Client,
        description: 'nav.description.about',
    },
    {
        route: '/about/roadmap',
        title: 'roadmap',
        icon: RouterIcon.ROADMAP,
        hasRole: CustomerRole.Client,
        description: 'nav.description.roadmap',
    },
    {
        route: '/alerts',
        title: 'alert.plural',
        icon: RouterIcon.ALERTS,
        hasRole: CustomerRole.OEM,
        description: 'nav.description.alerts',
    },
    {
        route: '/alerts/:id',
        title: 'alert',
        icon: RouterIcon.ALERTS,
        api: 'alerts',
        hasRole: CustomerRole.OEM,
    },
    {
        route: '/announcements',
        title: 'announcement.plural',
        icon: RouterIcon.ANNOUNCEMENTS,
        hasRole: CustomerRole.Client,
        description: 'nav.description.announcements',
    },
    {
        route: '/announcements/:id',
        title: 'announcement',
        icon: RouterIcon.ANNOUNCEMENTS,
    },
    {
        route: '/billing/:id',
        title: 'bill.billing',
        icon: RouterIcon.CASH,
        hasRole: CustomerRole.Super,
    },
    {
        route: '/dashboards',
        title: 'dashboard.plural',
        icon: RouterIcon.DASHBOARDS,
        hasRole: CustomerRole.Client,
        description: 'nav.description.dashboards',
    },
    {
        route: '/dashboards/overview',
        title: 'utilities.dashboard_overview',
        icon: RouterIcon.DASHBOARD_OVERVIEW,
        hasRole: CustomerRole.Super,
        description: 'nav.description.dashboard_overview',
    },
    {
        route: '/dashboards/:id',
        title: 'dashboard',
        icon: RouterIcon.DASHBOARDS,
        api: 'dashboards',
    },
    {
        route: '/data-files',
        title: 'data_file.plural',
        icon: RouterIcon.DATA_FILES,
        hasRole: CustomerRole.Client,
        description: 'nav.description.data_files',
    },
    {
        route: '/data-files/:id/charts',
        title: 'data_file',
        icon: RouterIcon.DATA_FILES,
        api: 'dataFiles',
    },
    {
        route: '/data-files/:id/map',
        title: 'data_file',
        icon: RouterIcon.DATA_FILES,
        api: 'dataFiles',
    },
    {
        route: '/data-files/:id',
        title: 'data_file.plural',
        icon: RouterIcon.DATA_FILES,
        api: 'dataFiles',
    },
    // TODO: remove this when /data-files is ready for production
    {
        route: '/data-files-alt',
        title: 'data_file.plural',
        icon: RouterIcon.DATA_FILES,
    },
    {
        route: '/devices/connections',
        title: 'device.connections',
        icon: RouterIcon.DEVICES_CONNECTION_LOG,
        hasRole: CustomerRole.Super,
        description: 'nav.description.connections',
    },
    {
        route: '/device-simulators',
        title: 'utilities.simulator',
        icon: RouterIcon.SIMULATOR,
        hasRole: CustomerRole.Super,
        description: 'nav.description.simulator',
    },
    {
        route: '/devices',
        title: 'device.plural',
        icon: RouterIcon.DEVICES,
        hasRole: CustomerRole.Client,
        description: 'nav.description.devices',
    },
    {
        route: '/devices/:id/connection-log',
        title: 'device.connection_log',
        icon: RouterIcon.DEVICES_CONNECTION_LOG,
    },
    {
        route: '/devices/:id/recent-data',
        title: 'data.recent',
        icon: 'timetable', // TODO: use RouterIcon
    },
    {
        route: '/devices/:id/diagnostics',
        title: 'diagnostic.plural',
        icon: RouterIcon.DIAGNOSTICS,
    },
    {
        route: '/devices/:id',
        title: 'device',
        icon: RouterIcon.DEVICES,
        api: 'devices',
    },
    {
        route: '/geo-fences',
        title: 'geo_fence.plural',
        icon: RouterIcon.GEO_FENCING,
        hasRole: CustomerRole.Dealer,
        description: 'nav.description.geo_fences',
    },
    {
        route: '/geo-fences/:id',
        title: 'geo_fence',
        icon: RouterIcon.GEO_FENCING,
        api: 'geoFences',
        hasRole: CustomerRole.Dealer,
    },
    { route: '/home', title: 'common.home', icon: RouterIcon.HOME },
    {
        route: '/models',
        title: 'model.plural',
        icon: RouterIcon.MODELS,
        hasRole: CustomerRole.Client,
        description: 'nav.description.models',
    },
    {
        route: '/models/:modelId/software-versions',
        title: 'software_version.plural',
        icon: RouterIcon.SOFTWARE_VERSIONS,
    },
    {
        route: '/models/:modelId/can-databases/:id',
        title: 'can_database.edit',
        icon: RouterIcon.DEFAULT,
        hasRole: CustomerRole.OEM,
    },
    {
        route: '/models/:id',
        title: 'model',
        icon: RouterIcon.MODELS,
        api: 'models',
    },
    {
        route: '/models/:modelId/modules/:id',
        title: 'module.release.plural',
        icon: RouterIcon.RELEASES,
        api: 'modules',
    },
    {
        route: '/organizations',
        title: 'organization.plural',
        icon: RouterIcon.ORGANIZATIONS,
        hasRole: CustomerRole.Dealer,
        description: 'nav.description.organizations',
    },
    {
        route: '/organizations/:id',
        title: 'organization',
        icon: RouterIcon.ORGANIZATIONS,
        api: 'organizations',
        hasRole: CustomerRole.Dealer,
    },
    {
        route: '/page-views',
        title: 'utilities.page_views',
        icon: RouterIcon.PAGE_VIEWS,
        hasRole: CustomerRole.Super,
        description: 'nav.description.page_views',
    },
    {
        route: '/reports',
        title: 'report.plural',
        icon: RouterIcon.REPORTS,
        hasRole: CustomerRole.Client,
        description: 'nav.description.reports',
    },
    {
        route: '/reports/:id',
        title: 'report.plural',
        icon: RouterIcon.REPORTS,
        api: 'reports',
    },
    { route: '/settings', title: 'setting.plural', icon: RouterIcon.SETTINGS },
    {
        route: '/ui-demo',
        title: 'demo.ui',
        icon: RouterIcon.UI_DEMO,
    },
    {
        route: '/users',
        title: 'user.plural',
        icon: RouterIcon.USERS,
        hasRole: CustomerRole.Client,
        description: 'nav.description.users',
    },
    {
        route: '/users/:id',
        title: 'user',
        icon: RouterIcon.USERS,
        api: 'users',
    },
    {
        route: '/widgets',
        title: 'custom_widget.plural',
        icon: RouterIcon.CUSTOM_WIDGETS,
        hasRole: CustomerRole.OEM,
        description: 'nav.description.custom_widgets',
    },
    {
        route: '/widgets/:id',
        title: 'custom_widget',
        icon: RouterIcon.CUSTOM_WIDGETS,
        api: 'widgets',
        hasRole: CustomerRole.OEM,
    },
    {
        route: environment.docsUrl,
        title: 'docs',
        icon: RouterIcon.DOCS,
        hasRole: CustomerRole.Client,
        description: 'nav.description.docs',
    },
];
